import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components';
import Img from 'gatsby-image'
import { BsChevronDoubleDown } from 'react-icons/bs';

const HeroDA = () => {
    const data = useStaticQuery(graphql`
        query ImageQuery {
            allDataAnalyticsJson {
            edges {
                node {
                alt
                img {
                    childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
                }
            }
            }
        }
      
    `)

    const getImage = (data) => {
        const imageArray = []
        data.allDataAnalyticsJson.edges.forEach((element, index) => {
            imageArray.push(
                <div key={index}>
                    <Image
                        alt={element.node.alt}
                        fluid={element.node.img.childImageSharp.fluid}>
                    </Image>
                    <Arrow />
                </div>
            )
        })
        return imageArray
    }

    return (
        <Container>{getImage(data)}</Container>
    )
}

export default HeroDA

const Container = styled.div`
    margin-top: 70px;
    height: auto;
`
const Image = styled(Img)`
    width:80%;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 1000px) {
     width:70%;       
    }

`
const Arrow = styled(BsChevronDoubleDown)`
   @media screen and (min-width: 1225px) {
    font-size: 1.8rem;
    color: #000;
    position: absolute;
    top: 45.5rem;
    right: 0;
}
  
`