import React from 'react'
import { GiClick } from 'react-icons/gi'
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import { Link } from "gatsby"

const InfoCard = () => {
    const data = useStaticQuery(graphql`
    query InfoQuery {
        allDataAnalyticsInfoJson {
          edges {
            node {
              altName
              description
              Image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      
    `)

    const getInfo = (data) => {
        const infoArray = []
        data.allDataAnalyticsInfoJson.edges.forEach((element, index) => {
            infoArray.push(
                <InfoWrapper key={index} data-sal="slide-up" data-sal-easing="ease">
                    <Image
                        alt={element.node.altName}
                        fluid={element.node.Image.childImageSharp.fluid}>
                    </Image>
                    <InfoP>{element.node.description}</InfoP>
                </InfoWrapper>
            )

        })
        return infoArray
    }

    return (
        <Container>
            <ContainerHeader>What you get</ContainerHeader>
            <ContainerWrapper >{getInfo(data)}</ContainerWrapper>
            <Link to='/usecase/'><Button ><GiClick css={`font-size: 20px`} /> Learn More on Use Case</Button></Link>
        </Container>
    )
}

export default InfoCard

const Container = styled.div`
    height: 100%;
    min-height: 60vh;
    padding: 1rem 1rem;
    
`
const ContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: rgba(127, 219, 247, 0.2);
    height: 70%;
    margin-bottom: 1rem;
    //border: 3px solid #3D6A78;
    border-radius: 2%;
    padding 1rem 0rem;
    
`
const ContainerHeader = styled.h2`
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 3rem; 
    text-align: center;
`
const InfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    line-height: 1.5rem;
    
`
const Image = styled(Img)`
    width: 10%;
    position: absolute;
    top: 5px;
    max-width:20px;
    min-width: 20px;
    
`
const InfoP = styled.p`
    text-align: left;
    font-size: clamp(1rem,2vw, 1.25rem); 
    padding: 0rem 1rem;
    
`
const Button = styled.button`
 width: 100%;
 background: #56E39F;
 border: none;
 padding: 0.5rem 0rem;
 border-radius: 5%;
 color: #fff;
 font-size: clamp(1rem,2vw, 1.25rem); 

 &:hover {
    background: #7FDBF7;
 }

 a {
     text-decoration: none;
 }
`