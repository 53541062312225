import React from 'react'
import styled from 'styled-components';

const Intro = () => {
    return (
        <Container>
            <ContainerHeader>Data Analytics</ContainerHeader>
            <ContainerP>“The goal is to turn data into information and information into insight.” – Carly Fiorina, former chief executive officer, Hewlett Packard.</ContainerP>
        </Container>
    )
}

export default Intro

const Container = styled.div`
    margin-top: 2rem;
    height: 30vh;

    @media screen and (min-width: 1000px) {
        margin-top: 0rem;       
        }
`
const ContainerHeader = styled.h2`
    font-size: clamp(1.3rem,3vw, 2rem);
    margin-bottom: 1rem; 
    text-align: center;
`
const ContainerP = styled.p`
    text-align: center;
    font-size: clamp(1rem,3vw, 1.25rem); 
    padding: 0rem 1rem;
    margin-bottom: 2rem;
`
