import React from 'react'
import Layout from '../components/layout';
import SEO from '../components/seo';
import Body from '../components/dataAnalytics/Body';


const DataAnalytics = () => (
    <Layout>
        <SEO title="Data Analytics Kenya - we extract useful information from data"
            description="We provide data analytics solutions, turn data into business insight to help you make accurate decisions in your business."
            keywords="data analytics, data science kenya, analytics of big data, big data analytics, data dashboard, data visualization, ai companies in kenya, data analytics services, data analysis companies in kenya, health data analytics in kenya, health data mining in kenya, health data visualization in kenya, artificial intelligence in healthcare, machine learning in healthcare in kenya, artificial intelligence companies in kenya, predictive analytics kenya, data science companies in kenya, Business intelligence in kenya, Health Business intelligence"
            img="https://www.tripleaimsoftware.com/static/04d6e676765064f535c589e6ec8442f2/ee604/HeroDA.png"
            siteUrl="https://www.tripleaimsoftware.com/data-analytics/">
        </SEO>
        <Body />
    </Layout>
)

export default DataAnalytics
